import React from 'react'

const Arrow = ({ className }) => {
  return (
    <svg
      className={`button__svg stroke-current inline-block${
        className !== undefined ? ' ' + className : ' ml-2'
      }`}
      width="16"
      height="15"
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.897 1h10.08v9.595M14.976 1L1 14.976" fill="none" />
    </svg>
  )
}

export default Arrow
