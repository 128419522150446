import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Form from '../components/Contact/form'
import Map from '../components/Contact/map'

const ContactPage = ({ data, location }) => {
  const global = data.prismicGlobal.data
  const page = data.prismicContact.data
  return (
    <Layout location={location}>
      <SEO
        title={page.meta_title && page.meta_title}
        location={location}
        description={page.meta_description && page.meta_description}
      />
      <div className="wrapper wrapper--y">
        <div className="mb-8 lg:mb-32">
          <h4 className="heading-xxs mb-2">{page.subheading}</h4>
          <h1 className="text-2xl font-semibold lg:heading-2xl">
            {page.heading}
          </h1>
        </div>

        <div className="lg:flex mb-20">
          <div className="lg:w-1/2 mb-16 lg:mb-0">
            <h4 className="heading-xxs mb-2">Phone</h4>
            <a
              className="link text-lg lg:text-2xl mb-8 leading-tight font-semibold inline-block"
              href={`tel:+64${global.phone.replace(/\s/g, '')}`}
            >
              {global.phone}
            </a>

            <h4 className="heading-xxs mb-2">Location</h4>
            <div
              className="text-lg lg:text-2xl mb-8 leading-tight font-semibold"
              dangerouslySetInnerHTML={{ __html: global.address.html }}
            />

            <h4 className="heading-xxs mb-2">PO Box</h4>
            <div
              className="text-lg lg:text-2xl mb-8 leading-tight font-semibold"
              dangerouslySetInnerHTML={{ __html: global.postal_address.html }}
            />
          </div>
          <div className="lg:w-1/2">
            <Form />
          </div>
        </div>
      </div>
      <Map
        coordinates={global.coordinates}
        googleMapURL={global.google_map_url}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageAboutQuery = graphql`
  query {
    prismicGlobal {
      data {
        heading
        address {
          html
          text
        }
        phone
        postal_address {
          html
          text
        }
        coordinates {
          latitude
          longitude
        }
        google_map_url {
          url
        }
      }
    }
    prismicContact {
      data {
        heading
        subheading
        meta_title
        meta_description
      }
    }
  }
`

export default ContactPage
