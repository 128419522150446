import React from 'react'
import axios from 'axios'
import { withFormik, Form, Field } from 'formik'

import RightChevron from '../SVG/RightChevron'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

/** constants */
const formName = 'contact'
const wrapperClass = 'lg:text-right'
const msgSuccess = 'Thanks, we’ll be in touch soon.'
const msgError = 'There was an error submitting the form, please try again.'

const Success = () => (
  <div className={wrapperClass}>
    {/* Success Message */}
    <div className="text-left">{msgSuccess}</div>
  </div>
)

const FormWithError = ({ isSubmitting, error }) => (
  <Form
    name={formName}
    className={wrapperClass}
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    <input type="hidden" name="form-name" value={formName} />
    {/* Name */}
    <div className="lg:flex lg:-ml-12">
      <div className="lg:w-1/2 lg:pl-12">
        <div className="input mb-10">
          <Field
            className="w-full text-lg lg:text-2xl font-semibold"
            name="firstName"
            type="text"
            placeholder="First Name"
            required
          />
        </div>
      </div>
      <div className="lg:w-1/2 lg:pl-12">
        <div className="input mb-10">
          <Field
            className="w-full text-lg lg:text-2xl font-semibold"
            name="lastName"
            type="text"
            placeholder="Last Name"
            required
          />
        </div>
      </div>
    </div>
    {/* email */}
    <div className="input mb-10">
      <Field
        className="w-full text-lg lg:text-2xl font-semibold"
        name="email"
        type="email"
        placeholder="Email"
        required
      />
    </div>
    {/* Phone */}
    <div className="input mb-10">
      <Field
        className="w-full text-lg lg:text-2xl font-semibold"
        name="phone"
        type="text"
        placeholder="Phone Number"
        required
      />
    </div>
    {/* Message */}
    <Field
      className="w-full h-80 bg-gradient-blue-20 mb-8 text-lg lg:text-2xl font-semibold"
      name="message"
      component="textarea"
      placeholder="Message"
    />
    {/* Button */}
    <button
      type="submit"
      disabled={isSubmitting}
      className="inline-block button button--white font-semibold border lg:py-4"
    >
      Submit
      <RightChevron className="ml-10" />
    </button>
    {/* Error Message */}
    {error && error.submit && <div className="mt-4">{msgError}</div>}
  </Form>
)

const DefaultForm = props => {
  const { status } = props
  if (status && status.success) {
    return <Success />
  } else {
    return <FormWithError {...props} />
  }
}

const FormikForm = withFormik({
  mapPropsToValues: values => ({
    firstName: values.firstName || '',
    lastName: values.lastName || '',
    email: values.email || '',
    phone: values.phone || '',
    message: values.message || '',
  }),
  handleSubmit: async (
    form,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await axios.post(
        '?no-cache=1',
        encode({ 'form-name': formName, ...form }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      resetForm()
      setStatus({ success: true })
    } catch (error) {
      // Set errors.submit to TRUE
      if (error.response) {
        console.log(error.response)
      } else {
        console.log(error)
      }
      setErrors({
        submit: true,
      })
      // REset error.submit to FALSE
      setTimeout(() => {
        setErrors({ submit: false })
      }, 5000)
    } finally {
      setSubmitting(false)
    }
  },
})(DefaultForm)

export default FormikForm
