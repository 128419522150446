import React from 'react'

import marker from '../../images/marker.svg'
import Arrow from '../SVG/Arrow.js'

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mapIsReady: false,
    }
  }

  componentDidMount() {
    const script = document.createElement('script')
    const API = 'AIzaSyBV6KuWZxnvtHzf_d-LKZ4tHZ-qOPqPCJo'
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API}`
    script.async = true
    script.addEventListener('load', () => {
      this.setState({ mapIsReady: true })
    })
    document.body.appendChild(script)
  }

  render() {
    const { googleMapURL, coordinates } = this.props

    if (this.state.mapIsReady) {
      this.map = new window.google.maps.Map(this.refs.map, {
        center: {
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        },
        zoom: 16,
        disableDefaultUI: true,
        gestureHandling: 'cooperative',
        styles: [
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e9e9e9',
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffffff',
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#ffffff',
              },
              {
                lightness: 29,
              },
              {
                weight: 0.2,
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
              {
                lightness: 18,
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#dedede',
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                visibility: 'on',
              },
              {
                color: '#ffffff',
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                saturation: 36,
              },
              {
                color: '#333333',
              },
              {
                lightness: 40,
              },
            ],
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [
              {
                color: '#f2f2f2',
              },
              {
                lightness: 19,
              },
            ],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#fefefe',
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#fefefe',
              },
              {
                lightness: 17,
              },
              {
                weight: 1.2,
              },
            ],
          },
        ],
      })

      var mapMarker = {
        url: marker,
        scaledSize: new window.google.maps.Size(80, 80),
      }

      var _this = this
      _this.marker = new window.google.maps.Marker({
        position: {
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        },
        map: _this.map,
        icon: mapMarker,
        optimized: false,
      })
    }

    return (
      <div className="relative">
        <div ref="map" className="w-full h-104 lg:h-156" />
        <a
          className="hidden lg:block button button--blue button--up-right absolute bottom-0 right-0 mr-16 mb-8"
          target="_blank"
          rel="noopener noreferrer"
          href={googleMapURL.url}
        >
          <div className="button__inner font-semibold">
            Get Directions
            <Arrow className="ml-12" />
          </div>
        </a>
      </div>
    )
  }
}

export default Map
